import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "px-3 pb-0" },
    [
      _vm.mostrarModalConfirmacao
        ? _c("ConfirmacaoModal", {
            attrs: {
              showDialog: _vm.mostrarModalConfirmacao,
              tituloModal: _vm.tituloModalConfirmacao,
              textoModal: _vm.textoModalConfirmacao,
              btnConfirmarEmit: _vm.btnConfirmarModalConfirmacao,
              esconderCancelar: false,
              btnConfirmar: _vm.btnConfirmarLabel
            },
            on: {
              invalidarInstrumento: _vm.invalidarInstrumento,
              fecharModal: function($event) {
                _vm.mostrarModalConfirmacao = false
                _vm.esconderCancelar = false
              }
            }
          })
        : _vm._e(),
      _c(
        VRow,
        [
          _c(VCol, [
            _c("h4", { staticClass: "bold" }, [_vm._v("Informações do Título")])
          ]),
          _c(
            VCol,
            { staticClass: "d-flex justify-end" },
            [
              _c("select-actions", {
                attrs: {
                  actions: [
                    {
                      name: "Irregularizar: Cartório inativo",
                      method: "irregularizarTitulo",
                      disabled: !_vm.statusParaIrregularizarTitulo.includes(
                        _vm.titulo.cd_status
                      )
                    },
                    {
                      name: "Irregularizar: Erro do cartório",
                      method: "irregularizarTituloK3",
                      disabled: !_vm.statusParaIrregularizarTituloK3.includes(
                        _vm.titulo.cd_status
                      )
                    },
                    {
                      name: "Reiniciar Envio",
                      method: "reiniciarEnvio",
                      disabled: _vm.desabilitaBotaoFila
                    },
                    {
                      name: "Invalidar Instrumento de Protesto",
                      method: "modalInvalidarInstrumento",
                      disabled: !_vm.instrumentoProtesto
                    }
                  ]
                },
                on: { executeAction: _vm.callMethod }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VRow,
        { staticClass: "mt-3" },
        [
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.cd_status + " - " + _vm.titulo.ds_status,
                  label: "Status",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3", attrs: { cols: "2" } },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.id_titulo,
                  label: "ID",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3", attrs: { cols: "2" } },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.ds_numero_titulo,
                  label: "Número do Título",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3", attrs: { cols: "2" } },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.nu_parcela,
                  label: "Parcela",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3", attrs: { cols: "2" } },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.ds_nosso_numero,
                  label: "Nosso Número",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VRow,
        { staticClass: "mt-0" },
        [
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: "R$ " + _vm.titulo.vl_saldo_protesto_formatado,
                  label: "Valor Protestado",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: "R$" + _vm.titulo.vl_titulo_formatado,
                  label: "Valor total do Título",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.formatDate(_vm.titulo.dt_emissao),
                  label: "Data de emissão",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.formatDate(_vm.titulo.dt_vencimento),
                  label: "Data de vencimento",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: "R$ " + (_vm.titulo.vl_repassado_formatado || "0,00"),
                  label: "Valor Repasse",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: "R$ " + (_vm.titulo.vl_custas_formatado || "0,00"),
                  label: "Custa Cancelamento",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VRow,
        { staticClass: "mt-0" },
        [
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.cd_especie_titulo,
                  label: "Espécie",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.tp_declaracao,
                  label: "Declaração",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.tp_endosse || " ",
                  label: "Endosso",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.tp_aceite,
                  label: "Aceite",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.cd_falimentar || "  ",
                  label: "Falimentar",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-3" },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.ds_agrupador,
                  label: "Agrupador",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.feedbackSnackbar
        ? _c("Snackbar", {
            attrs: {
              "data-html2canvas-ignore": "",
              mostrarSnackbar: _vm.feedbackSnackbar,
              corSnackbar: _vm.feedbackSnackbarColor,
              mensagemSnackbar: _vm.mensagemfeedbackSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.feedbackSnackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }