import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "pa-3 card-mensageria" },
    [
      _c(
        VRow,
        [
          _c(VCol, [
            _c("h4", { staticClass: "bold" }, [_vm._v("Mensageria")])
          ])
        ],
        1
      ),
      _c(
        VRow,
        { staticClass: "mt-0" },
        [
          _c(VCol, [
            _c("div", { staticClass: "list-alert" }, [
              _vm.superUsuario && _vm.usuarioRecuperi
                ? _c(
                    "div",
                    [
                      _c(VIcon, { attrs: { color: _vm.alertTypeEmail } }, [
                        _vm._v(
                          _vm._s(
                            _vm.alertTypeEmail === "success"
                              ? "mdi-check-circle"
                              : "mdi-alert-circle"
                          )
                        )
                      ]),
                      _c("span", {
                        staticClass: "ml-2",
                        domProps: { innerHTML: _vm._s(_vm.alertInfoEmail) }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.superUsuario && _vm.usuarioRecuperi
                ? _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c(VIcon, { attrs: { color: _vm.alertTypeCel } }, [
                        _vm._v(
                          _vm._s(
                            _vm.alertTypeCel === "success"
                              ? "mdi-check-circle"
                              : "mdi-alert-circle"
                          )
                        )
                      ]),
                      _c("span", {
                        staticClass: "ml-2",
                        domProps: { innerHTML: _vm._s(_vm.alertInfoCel) }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.superUsuario && _vm.usuarioRecuperi
                ? _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        VIcon,
                        { attrs: { color: _vm.alertTypeCelCheck } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.alertTypeCelCheck === "success"
                                ? "mdi-check-circle"
                                : "mdi-alert-circle"
                            )
                          )
                        ]
                      ),
                      _c("span", {
                        staticClass: "ml-2",
                        domProps: { innerHTML: _vm._s(_vm.alertInfoCelCheck) }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.superUsuario && _vm.usuarioRecuperi
                ? _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        VIcon,
                        {
                          attrs: {
                            color: _vm.habilitadoNotifica ? "success" : "info"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.habilitadoNotifica === "success"
                                ? "mdi-check-circle"
                                : "mdi-alert-circle"
                            )
                          )
                        ]
                      ),
                      _vm.habilitadoNotifica
                        ? _c("span", {
                            staticClass: "ml-2",
                            domProps: {
                              innerHTML: _vm._s(
                                "<strong>Notifica:</strong> o Apresentante está habilitado na Notifica"
                              )
                            }
                          })
                        : _c("span", {
                            staticClass: "ml-2",
                            domProps: {
                              innerHTML: _vm._s(
                                "<strong>Notifica:</strong> o Apresentante não está habilitado na Notifica"
                              )
                            }
                          })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }