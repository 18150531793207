import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "pa-3" },
    [
      _c(
        VRow,
        [
          _c(VCol, { attrs: { cols: "6" } }, [
            _c("h4", { staticClass: "bold" }, [_vm._v("Sacador")])
          ]),
          _c(VCol, [_c("h4", { staticClass: "bold" }, [_vm._v("Cedente")])]),
          _c(VCol, { staticClass: "d-flex justify-end" })
        ],
        1
      ),
      _c(
        VRow,
        [
          _c(
            VCol,
            { staticClass: "py-1", attrs: { cols: "4" } },
            [
              _c(VTextField, {
                attrs: {
                  dense: "",
                  outlined: "",
                  value: _vm.titulo.nm_sacador,
                  label: "Nome do sacador",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "py-1" },
            [
              _c(VTextField, {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["##.###.###/####-##", "###.###.###-##"],
                    expression: "['##.###.###/####-##', '###.###.###-##']"
                  }
                ],
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.cnpj_sacador,
                  label: "CNPJ",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "py-1", attrs: { cols: "6" } },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.nm_cedente_favorecido,
                  label: "Nome do cedente favorecido",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VRow,
        { staticClass: "mt-0" },
        [
          _c(VCol, { attrs: { cols: "6" } }, [
            _c("h4", { staticClass: "bold" }, [_vm._v("Apresentante")])
          ])
        ],
        1
      ),
      _c(
        VRow,
        { staticClass: "pb-3" },
        [
          _c(
            VCol,
            { staticClass: "py-1", attrs: { cols: "4" } },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.nm_razao_social,
                  label: "Razão social",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "py-1", attrs: { cols: "2" } },
            [
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.cd_apresentante,
                  label: "Código",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }