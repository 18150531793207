import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "pa-3 card-historico" },
    [
      _c(
        VRow,
        [
          _c(VCol, { attrs: { cols: "6" } }, [
            _c("h4", { staticClass: "bold" }, [_vm._v("Historico de status")])
          ])
        ],
        1
      ),
      _c(
        VRow,
        [
          _c(VCol, [
            _c(
              "div",
              { staticClass: "bg-historico" },
              _vm._l(_vm.historico, function(status, index) {
                return _c("ul", { key: index }, [
                  _c(
                    "li",
                    [
                      _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(
                              status.dt_arquivo_movimento
                                ? _vm.dtMovimentoFormat(
                                    status.dt_arquivo_movimento
                                  )
                                : _vm.dtMovimentoFormat(status.dt_historico)
                            ) +
                            ": "
                        )
                      ]),
                      _vm._v(" " + _vm._s(status.DESCRICAO) + " "),
                      _c(
                        VIcon,
                        {
                          staticClass: "ml-3",
                          on: {
                            click: function($event) {
                              ;(_vm.statusSelected = status),
                                (_vm.dialogConfirm = true)
                            }
                          }
                        },
                        [_vm._v("mdi-trash-can-outline")]
                      )
                    ],
                    1
                  )
                ])
              }),
              0
            )
          ])
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "550" },
          model: {
            value: _vm.dialogConfirm,
            callback: function($$v) {
              _vm.dialogConfirm = $$v
            },
            expression: "dialogConfirm"
          }
        },
        [
          _c(
            VCard,
            { attrs: { "max-width": "550" } },
            [
              _c(VCardTitle, { staticClass: "headline primary--text" }, [
                _vm._v("Confirmação")
              ]),
              _c(VCardText, [
                _vm._v(" Deseja realmente excluir o histórico? ")
              ]),
              _c(VCardText, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.statusSelected.dt_arquivo_movimento
                        ? _vm.dtMovimentoFormat(
                            _vm.statusSelected.dt_arquivo_movimento
                          )
                        : _vm.dtMovimentoFormat(_vm.statusSelected.dt_historico)
                    ) +
                    ": " +
                    _vm._s(_vm.statusSelected.DESCRICAO) +
                    " "
                )
              ]),
              _c(
                VCardActions,
                [
                  _c(
                    VBtn,
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogConfirm = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    VBtn,
                    {
                      on: {
                        click: function($event) {
                          return _vm.deleteHistory(
                            _vm.statusSelected.id_historico
                          )
                        }
                      }
                    },
                    [_vm._v("Confirmar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.feedbackSnackbar
        ? _c("Snackbar", {
            attrs: {
              "data-html2canvas-ignore": "",
              mostrarSnackbar: _vm.feedbackSnackbar,
              corSnackbar: _vm.feedbackSnackbarColor,
              mensagemSnackbar: _vm.mensagemfeedbackSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.feedbackSnackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }