import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "pa-3 card-historico-emails" },
    [
      _c(
        VRow,
        [
          _c(VCol, [
            _c("h4", { staticClass: "bold" }, [_vm._v("Historico de E-mails")])
          ]),
          _c(
            VCol,
            { staticClass: "d-flex justify-end" },
            [
              _vm.listaEnvios.length
                ? _c(
                    VBtn,
                    {
                      attrs: { loading: _vm.loadingTituloImpressao },
                      on: {
                        click: function($event) {
                          return _vm.historicoImpressao()
                        }
                      }
                    },
                    [
                      _c(VIcon, [_vm._v("mdi-printer")]),
                      _vm._v(" Imprimir ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        VRow,
        [
          _c(
            VCol,
            [
              _c(VDataTable, {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.listaEnvios,
                  loading: _vm.loading,
                  "server-items-length": _vm.listaEnvios.length,
                  "loading-text": "Buscando envios...",
                  "item-key": "comarcas",
                  "no-data-text": "Nenhum E-mail Enviado",
                  "footer-props": {
                    itemsPerPageOptions: [20, 100, 200, -1]
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.dt_envio",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatDate(item.dt_envio)))
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.dt_abertura",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatDate(item.dt_abertura)))
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.dt_clique",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatDate(item.dt_clique)))
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.dt_entregue",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatDate(item.dt_entregue)))
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.cd_status",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.cd_status == "102"
                            ? _c("span", [_c("p", [_vm._v("Protestado")])])
                            : _vm._e(),
                          item.cd_status == "225"
                            ? _c("span", [_c("p", [_vm._v("Apontado")])])
                            : _vm._e(),
                          item.cd_status == "241"
                            ? _c("span", [
                                _c("p", [_vm._v("Enviado a Cancelamento")])
                              ])
                            : _vm._e(),
                          item.cd_status == "251"
                            ? _c("span", [
                                _c("p", [_vm._v("Enviado a Anuência")])
                              ])
                            : _vm._e(),
                          item.cd_status == "252"
                            ? _c("span", [
                                _c("p", [_vm._v("Anuência Programada")])
                              ])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.st_ativo",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            VIcon,
                            {
                              style: {
                                color: item.st_ativo != "" ? "green" : "red"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.getBolean(item.st_ativo)))]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.is_admin",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            VIcon,
                            {
                              style: {
                                color: item.is_admin != "" ? "green" : "red"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.getBolean(item.is_admin)))]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.tracking",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.tracking == "2"
                            ? _c(
                                "span",
                                { staticClass: "d-flex flex-row" },
                                [
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "", color: "success" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          elevation: "0",
                                                          "x-small": "",
                                                          fab: ""
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      VIcon,
                                                      {
                                                        staticClass:
                                                          "ma-2 pa-2 blue--text"
                                                      },
                                                      [_vm._v("mdi-check-all")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "E-mail visualizado pelo destinatário"
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "", color: "success" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          elevation: "0",
                                                          "x-small": "",
                                                          fab: ""
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      VIcon,
                                                      {
                                                        staticClass: "ma-2 pa-2"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-cursor-default-click"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Essa mensagem recebeu cliques")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.tracking == "1"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "", color: "success" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          fab: "",
                                                          elevation: "0"
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      VIcon,
                                                      {
                                                        staticClass:
                                                          "ma-2 pa-2 blue--text"
                                                      },
                                                      [_vm._v("mdi-check-all")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "E-mail visualizado pelo destinatário"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.tracking == "0"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "", color: "success" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          fab: "",
                                                          elevation: "0"
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      VIcon,
                                                      {
                                                        staticClass:
                                                          "ma-2 pa-2 grey--text"
                                                      },
                                                      [_vm._v("mdi-check")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "E-mail Entregue ao destinatário"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.tracking == null
                            ? _c(
                                "span",
                                [
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "", color: "grey" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          elevation: "0",
                                                          "x-small": "",
                                                          fab: ""
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      VIcon,
                                                      {
                                                        staticClass: "ma-2 pa-2"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-dots-horizontal"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [_c("span", [_vm._v("E-mail enviado")])]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.action",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            VIcon,
                            {
                              staticClass: "mr-2",
                              style: { color: "black" },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalhe(item)
                                }
                              }
                            },
                            [_vm._v("mdi-eye-outline")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: {
            transition: "dialog-bottom-transition",
            "max-width": "1000px"
          },
          model: {
            value: _vm.showTemplateDialog,
            callback: function($$v) {
              _vm.showTemplateDialog = $$v
            },
            expression: "showTemplateDialog"
          }
        },
        [
          _vm.selectedEmail
            ? _c(_vm.renderTemplate(), {
                tag: "component",
                attrs: {
                  usuarioPartner: _vm.usuarioPartner,
                  nomeDevedor: _vm.titulo.nm_devedor,
                  nomeExibicaoApresentante: _vm.NomeExibicaoApresentante
                },
                on: {
                  fecharModal: function($event) {
                    _vm.showTemplateDialog = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }