import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VToolbar,
        { staticClass: "toolbar", attrs: { color: "primary", dark: "" } },
        [
          _c(
            VRow,
            { attrs: { align: "center" } },
            [
              _c(
                VCol,
                { staticClass: "d-flex", attrs: { cols: "12", md: "6" } },
                [
                  _c(VIcon, { attrs: { large: "" } }, [
                    _vm._v("mdi-file-document-outline")
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c("h4", [_vm._v(_vm._s(_vm.title))]),
                    _c("h3", [_vm._v(_vm._s(_vm.titulo.ds_nosso_numero))])
                  ])
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "d-flex justify-end",
                  attrs: { "data-html2canvas-ignore": "" }
                },
                [
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VBtn,
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "white--text",
                                      style: _vm.retornarValorInstrumentoProtesto(
                                        _vm.titulo,
                                        "style"
                                      ),
                                      attrs: { fab: "", dark: "", small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.verificarFuncaoInstrumentos(
                                            _vm.titulo
                                          )
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(VIcon, { attrs: { dark: "" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.retornarValorInstrumentoProtesto(
                                            _vm.titulo,
                                            "icon"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.retornarValorInstrumentoProtesto(
                                _vm.titulo,
                                "tooltip"
                              )
                            ) +
                            " "
                        )
                      ])
                    ]
                  ),
                  _vm.problemaMensageria
                    ? _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g({ attrs: { icon: "" } }, on),
                                      [
                                        _c(VIcon, { attrs: { large: "" } }, [
                                          _vm._v("mdi-email-alert-outline")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4045106228
                          )
                        },
                        [
                          _c("span", [
                            _vm._v("Há problema de mensageria para este título")
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VBtn,
                                _vm._g(
                                  {
                                    attrs: { icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.copyLink()
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c(VIcon, { attrs: { large: "" } }, [
                                    _vm._v("mdi-link")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_vm._v(" Copiar link deste título ")]
                  ),
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VBtn,
                                _vm._g({ attrs: { icon: "" } }, on),
                                [
                                  !_vm.loadingTituloImpressao
                                    ? _c(
                                        VIcon,
                                        {
                                          attrs: { large: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.imprimirTituloPDF(
                                                _vm.titulo
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("mdi-printer-outline")]
                                      )
                                    : _c(VProgressCircular, {
                                        attrs: { dark: "", indeterminate: "" }
                                      })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Imprimir este título")])]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.snackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbar,
              corSnackbar: _vm.colorSnackbar,
              mensagemSnackbar: _vm.mensagemSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }