import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "pa-3" },
    [
      _c(
        VRow,
        [
          _c(VCol, [
            _c("h4", { staticClass: "bold" }, [_vm._v("Comentarios do Título")])
          ])
        ],
        1
      ),
      _c(
        VRow,
        [
          _c(
            VCol,
            [
              !_vm.comentarios.length
                ? _c("span", [_vm._v("Não há comentarios neste título")])
                : _c(
                    VList,
                    [
                      _c(
                        VListItemGroup,
                        _vm._l(_vm.comentarios, function(comentario, index) {
                          return _c(
                            VListItem,
                            { key: index, staticClass: "bg-comentario" },
                            [
                              _c(
                                VListItemContent,
                                [
                                  _c(VListItemTitle, [
                                    _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(comentario.email_user) +
                                          "(ID: " +
                                          _vm._s(comentario.id_user) +
                                          "/" +
                                          _vm._s(comentario.name_user) +
                                          ") - Criado em " +
                                          _vm._s(
                                            _vm.FormateData(
                                              comentario.dt_criacao
                                            )
                                          ) +
                                          ": "
                                      ),
                                      _c("i", { staticClass: "text-gray" }, [
                                        _vm._v(_vm._s(comentario.comentario))
                                      ])
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm.addComentario
        ? _c(
            VRow,
            [
              _c(
                VCol,
                [
                  _c(VTextarea, {
                    attrs: {
                      label: "Novo comentario",
                      outlined: "",
                      placeholder: "Escreva aqui seu comentario"
                    },
                    model: {
                      value: _vm.novoComentario,
                      callback: function($$v) {
                        _vm.novoComentario = $$v
                      },
                      expression: "novoComentario"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.addComentario
        ? _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { color: "red", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.addComentario = false
                        }
                      }
                    },
                    [_c(VIcon, [_vm._v("mdi-close")]), _vm._v(" Cancelar ")],
                    1
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "ml-3",
                      attrs: { loading: _vm.loadingSave, color: "success" },
                      on: {
                        click: function($event) {
                          return _vm.criarComentario()
                        }
                      }
                    },
                    [_c(VIcon, [_vm._v("mdi-check")]), _vm._v(" Salvar ")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.addComentario
        ? _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    VBtn,
                    {
                      on: {
                        click: function($event) {
                          _vm.addComentario = true
                        }
                      }
                    },
                    [
                      _c(VIcon, [_vm._v("mdi-plus")]),
                      _vm._v(" Adicionar comentario ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }