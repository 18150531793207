import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "pa-3" },
    [
      _c(
        VRow,
        [
          _c(VCol, [
            _c("h4", { staticClass: "bold" }, [
              _vm._v("Lista de arquivos Remessa/Confirmação")
            ])
          ])
        ],
        1
      ),
      _c(
        VRow,
        [
          _c(
            VCol,
            [
              _c(
                VList,
                [
                  _c(
                    VListItemGroup,
                    _vm._l(_vm.arquivos, function(item, index) {
                      return _c(
                        VListItem,
                        { key: index },
                        [
                          _c(
                            VListItemContent,
                            [
                              _c(VListItemTitle, [
                                _vm._v(_vm._s(item.nm_arquivo_cra))
                              ]),
                              _c(VListItemSubtitle, [
                                _vm._v(
                                  "Status: " +
                                    _vm._s(item.cd_status) +
                                    " | Comarca: " +
                                    _vm._s(item.cd_ibge_envio_comarca) +
                                    " | UF: " +
                                    _vm._s(item.cd_uf)
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            VListItemAction,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.downloadFile(item)
                                    }
                                  }
                                },
                                [_c(VIcon, [_vm._v("mdi-download")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }